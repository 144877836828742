import {
  FETCH_USER_TEAMS_QUERY,
  FetchUserTeamsQueryResponse
} from '../../../users/queries/fetchUserTeams.query';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useUser } from '../../../users/hooks/useUser';

import { ProjectNanoID } from '../../projectsTypes';

import { CreateProjectInTeamFormProvider } from '../../components/forms/CreateProjectInTeamForm/hooks/useCreateProjectInTeamFormContext';
import { CreateProjectInTeamForm } from '../../components/forms/CreateProjectInTeamForm';
import { ShowItemLayout } from '../../../common/layouts/ShowItemLayout';

import { LoadingSkeleton } from '../../../../helpers/LoadingSkeleton';

import { UserCache } from '../../../users/UserCache';

import { ProjectsPermissions } from '../../projectsConstants';

import { projectsKeys } from '../../../../locales/keys';

interface CreateProjectInTeamPageProps {
  afterCreateProject: (projectNanoId: ProjectNanoID) => Promise<boolean>;
}

function CreateProjectInTeamPage({
  afterCreateProject
}: CreateProjectInTeamPageProps) {
  const currentUser = useCurrentUser();

  const { user, userFetched } = useUser<FetchUserTeamsQueryResponse>({
    cacheKey: UserCache.showUserTeamsCacheKey(currentUser.nanoId),
    query: FETCH_USER_TEAMS_QUERY,
    uuid: currentUser.nanoId
  });

  const company = user?.client ? user?.currentTeam : null;

  const ownerId = user?.client ? user?.id : null;

  return (
    <ShowItemLayout
      action={ProjectsPermissions.READ_CREATE_PROJECT_IN_TEAM_PAGE}
      i18nTitle={projectsKeys.create}
      withoutSecondaryMenu
    >
      <LoadingSkeleton loaded={userFetched}>
        <CreateProjectInTeamFormProvider
          afterCreateProject={afterCreateProject}
          ownerId={ownerId}
          preferredPaymentMethod={company?.preferredPaymentMethod}
          teamNanoId={company?.nanoId}
          teamTerms={company?.terms}
        >
          <CreateProjectInTeamForm />
        </CreateProjectInTeamFormProvider>
      </LoadingSkeleton>
    </ShowItemLayout>
  );
}

export default CreateProjectInTeamPage;
