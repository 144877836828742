import { gql } from 'graphql-request';

import {
  UserID,
  UserUUID,
  UserCreatedAt,
  UserFavorite,
  UserFullName,
  UserEmail,
  UserPhone,
  UserImageUUID,
  UserImageFile,
  UserNanoID,
  UserFinanceRoleName,
  UserClient,
  UserBlocked,
  UserCurrentTeamNanoID,
  UserCurrentTeamName,
  UserWorkExperience,
  UserFinanceRoleId,
  UserSystemAccount,
  UserIsMentor,
  UserPayoneerId,
  UserPayoneerIdPe,
  UserPayoneerIdAvltd,
  UserPayoneerIdPeAvltd,
  UserPayoneerType,
  UserLocale,
  UserCdn,
  UserRoleId,
  UserGroups,
  UserFinanceTags,
  UserAdditionalInformation,
  UserQualifications,
  UserSpecializations,
  UserPosition,
  UserName,
  UserSurname,
  UserCity,
  UserAddress,
  UserSite,
  UserAirTableApiKey,
  UserAirTableBaseId,
  UserAirTableEmail,
  UserAirTablePassword,
  UserAirTableTableName
} from '../usersTypes';

export interface FetchSingeUserQueryResponse {
  id: UserID;
  uuid: UserUUID;
  nanoId: UserNanoID;
  favorite: UserFavorite;
  fullName: UserFullName;
  name: UserName;
  surname: UserSurname;
  city: UserCity;
  address: UserAddress;
  site: UserSite;
  email: UserEmail;
  phone: UserPhone;
  financeRoleName: UserFinanceRoleName;
  createdAt: UserCreatedAt;
  client: UserClient;
  blocked: UserBlocked;
  systemAccount: UserSystemAccount;
  isMentor: UserIsMentor;
  payoneerId: UserPayoneerId;
  payoneerIdPe: UserPayoneerIdPe;
  payoneerIdAvltd: UserPayoneerIdAvltd;
  payoneerIdPeAvltd: UserPayoneerIdPeAvltd;
  payoneerIdType: UserPayoneerType;
  locale: UserLocale;
  cdn: UserCdn;
  airTableApiKey: UserAirTableApiKey;
  airTableBaseId: UserAirTableBaseId;
  airTableEmail: UserAirTableEmail;
  airTablePassword: UserAirTablePassword;
  airTableTableName: UserAirTableTableName;
  roles: {
    id: UserRoleId;
  }[];
  position: UserPosition;
  specializations: UserSpecializations;
  qualifications: UserQualifications;
  clientManager: {
    id: UserID;
  };
  salesManager: {
    id: UserID;
  };
  projectManager: {
    id: UserID;
  };
  mentor: { id: UserID };
  groups: UserGroups;
  financeTags: UserFinanceTags;
  additionalInformation: UserAdditionalInformation;
  currentTeam: {
    nanoId: UserCurrentTeamNanoID;
    name: UserCurrentTeamName;
  };
  financeRole: {
    id: UserFinanceRoleId;
  };
  image: {
    uuid: UserImageUUID;
    file: UserImageFile;
  };
  workExperience: UserWorkExperience;
}

export const FETCH_SINGE_USER_QUERY = gql`
  query IndexUser($uuid: ID!) {
    user(uuid: $uuid) {
      id
      uuid
      nanoId
      favorite
      fullName
      name
      surname
      city
      address
      site
      email
      phone
      financeRoleName
      createdAt
      client
      blocked
      systemAccount
      isMentor
      payoneerId
      payoneerIdPe
      payoneerIdAvltd
      payoneerIdPeAvltd
      payoneerIdType
      locale
      cdn
      airTableApiKey
      airTableBaseId
      airTableEmail
      airTablePassword
      airTableTableName
      position {
        id
      }
      specializations {
        id
      }
      qualifications {
        id
      }
      projectManager {
        id
      }
      clientManager {
        id
      }
      salesManager {
        id
      }
      mentor {
        id
      }
      groups {
        id
      }
      financeTags {
        id
      }
      additionalInformation
      roles {
        id
      }
      currentTeam {
        nanoId
        name
      }
      financeRole {
        id
      }
      image {
        uuid
        file
      }
      workExperience
    }
  }
`;
