import { useQueryClient, QueryKey } from 'react-query';

interface UserResponse<ItemType> {
  user: ItemType | null;
}

interface CachedUserOptions {
  cacheKey: QueryKey;
}

function useCachedUser<ItemType>({ cacheKey }: CachedUserOptions) {
  const queryClient = useQueryClient();

  const queryData = queryClient.getQueryData<UserResponse<ItemType>>(cacheKey);

  const user = queryData?.user;

  return {
    user
  };
}

export default useCachedUser;
