import { UserNanoID } from '../../usersTypes';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';

import { SingleUserShowLayout } from '../../layouts/SingleUserShowLayout';

import { SingleUserDashboardIndexPage } from '../../components/content/SingleUserDashboardIndexPage';

import { UsersPermissions } from '../../usersConstants';

interface SingleUserDashboardPageProps {
  providedUserNanoId?: UserNanoID;
}

function SingleUserDashboardPage({
  providedUserNanoId
}: SingleUserDashboardPageProps) {
  const currentUser = useCurrentUser();

  const userNanoId = providedUserNanoId
    ? providedUserNanoId
    : currentUser.nanoId;

  const selfProfile = userNanoId === currentUser.nanoId;

  const pageAction = selfProfile
    ? UsersPermissions.READ_SELF_DASHBOARD_PAGE
    : UsersPermissions.READ_USER_DASHBOARD_PAGE;

  return (
    <SingleUserShowLayout
      action={pageAction}
      userNanoId={userNanoId}
      wrapperClassName="px-6 py-8 bg-gray-50 dark:bg-transparent min-h-full"
    >
      <SingleUserDashboardIndexPage userNanoId={userNanoId} />
    </SingleUserShowLayout>
  );
}

export default SingleUserDashboardPage;
