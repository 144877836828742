import React from 'react';

import { SingleUserPageHeaderUser } from '../SingleUserProfileSecondaryMenuWrapperHead/SingleUserProfileSecondaryMenuWrapperHead.types';

import { UserNanoID } from '../../../usersTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useThirdPersonView } from '../../../../../app/hooks/useThirdPersonView';

import { SecondaryMenuWrapper } from '../../../../../app/components/menus/SecondaryMenuWrapper';

import { MenuPlainLink } from '../../../../common/components/menus/MenuPlainLink';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { UserTabs, UsersPermissions } from '../../../usersConstants';
import { SingleUserPath } from '../../../SingleUserPath';
import { TeamPath } from '../../../../teams/TeamPath';
import { IconsEnum } from '../../../../../assets/icons/types';
import { profilesKeys } from '../../../../../locales/keys';

interface UserProfileSecondaryMenuProps {
  userNanoId: UserNanoID;
  user: SingleUserPageHeaderUser;
  userFetched: boolean;
  active: UserTabs;
}

function SingleUserProfileSecondaryMenu({
  userNanoId,
  user,
  userFetched,
  active
}: UserProfileSecondaryMenuProps) {
  const { isThirdPersonView, companyNanoId } = useThirdPersonView();

  const currentUser = useCurrentUser();

  const selfProfile = userNanoId === currentUser.nanoId;

  const profileHref = selfProfile
    ? SingleUserPath.profile()
    : SingleUserPath.userProfile(userNanoId);

  const thirdPersonViewProfileHref = isThirdPersonView
    ? TeamPath.companyUserProfile(companyNanoId, userNanoId)
    : null;

  const filesHref = selfProfile
    ? SingleUserPath.attachments()
    : SingleUserPath.userAttachments(userNanoId);

  const thirdPersonViewFilesHref = isThirdPersonView
    ? TeamPath.companyUserAttachments(companyNanoId, userNanoId)
    : null;

  const recordsHref = selfProfile
    ? SingleUserPath.records()
    : SingleUserPath.userRecords(userNanoId);

  const thirdPersonViewRecordsHref = isThirdPersonView
    ? TeamPath.companyUserRecords(companyNanoId, userNanoId)
    : null;

  const reportsHref = selfProfile
    ? SingleUserPath.reports()
    : SingleUserPath.userReports(userNanoId);

  const requirementsHref = selfProfile
    ? SingleUserPath.requirements()
    : SingleUserPath.userRequirements(userNanoId);

  const thirdPersonViewRequirementsHref = isThirdPersonView
    ? TeamPath.companyUserRequirements(companyNanoId, userNanoId)
    : null;

  return (
    <SecondaryMenuWrapper user={user} userLoaded={user && userFetched}>
      <div className="p-4">
        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_PROFILE_SHOW_PAGE
              : UsersPermissions.READ_USER_PROFILE_SHOW_PAGE
          }
        >
          <MenuPlainLink
            active={active === UserTabs.PROFILE}
            href={thirdPersonViewProfileHref || profileHref}
            i18nText={profilesKeys.singular}
            icon={IconsEnum.USER_CIRCLE_SOLID}
          />
        </CheckPermissions>

        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_RECORDS_PAGE
              : UsersPermissions.READ_USER_RECORDS_PAGE
          }
        >
          <MenuPlainLink
            active={active === UserTabs.RECORDS}
            href={thirdPersonViewRecordsHref || recordsHref}
            i18nText={profilesKeys.records}
            icon={IconsEnum.BOOK_OPEN_SOLID}
          />
        </CheckPermissions>

        <CheckPermissions
          action={
            selfProfile
              ? UsersPermissions.READ_SELF_FILES_PAGE
              : UsersPermissions.READ_USER_FILES_PAGE
          }
        >
          <MenuPlainLink
            active={active === UserTabs.FILES}
            href={thirdPersonViewFilesHref || filesHref}
            i18nText={profilesKeys.files}
            icon={IconsEnum.FOLDER_SOLID}
          />
        </CheckPermissions>

        {!user?.client && (
          <CheckPermissions
            action={
              selfProfile
                ? UsersPermissions.READ_SELF_REPORTS_PAGE
                : UsersPermissions.READ_USER_REPORTS_PAGE
            }
          >
            <MenuPlainLink
              active={active === UserTabs.REPORTS}
              href={reportsHref}
              i18nText={profilesKeys.reports}
              icon={IconsEnum.CHART_BAR_SQUARE_SOLID}
            />
          </CheckPermissions>
        )}

        {user?.client && (
          <CheckPermissions
            action={
              selfProfile
                ? UsersPermissions.READ_SELF_REQUIREMENTS_PAGE
                : UsersPermissions.READ_USER_REQUIREMENTS_PAGE
            }
          >
            <MenuPlainLink
              active={active === UserTabs.REQUIREMENTS}
              href={thirdPersonViewRequirementsHref || requirementsHref}
              i18nText={profilesKeys.requirements}
              icon={IconsEnum.EXCLAMATION_CIRCLE}
            />
          </CheckPermissions>
        )}
      </div>
    </SecondaryMenuWrapper>
  );
}

export default SingleUserProfileSecondaryMenu;
