import { gql } from 'graphql-request';

export const FETCH_PROJECTS_TOTAL_COUNT_QUERY = gql`
  query ProjectsTotalCountQuery($projectsFilters: ProjectsFilters) {
    projects(filters: $projectsFilters) {
      paginationInfo {
        totalCount
      }
    }
  }
`;
