import { useCallback } from 'react';

import { useRouter } from 'next/router';

import { ProjectNanoID } from '../../../projects/projectsTypes';

import { FETCH_PROJECTS_TOTAL_COUNT_QUERY } from '../../../projects/queries/fetchProjectsTotalCount.query';

import { useCurrentUser } from '../../../../auth/hooks/useAuth';
import { useTotalsQuery } from '../../../common/hooks/base/reactQuery/useTotalsQuery';

import { CreateProjectInTeamPage } from '../../../projects/pages/CreateProjectInTeamPage';
import { TeamDashboardPage } from '../../../teams/pages/TeamDashboardPage';
import { SingleUserDashboardPage } from '../../../users/pages/SingleUserDashboardPage';

import { ProjectCache } from '../../../projects/ProjectCache';
import { ProjectPath } from '../../../projects/ProjectPath';

type MyProjectsTotalCountQueryKey = 'projects';

function DashboardPage() {
  const { push } = useRouter();

  const handleAfterCreateProject = useCallback<
    (projectNanoId: ProjectNanoID) => Promise<boolean>
  >(
    (projectNanoId) =>
      projectNanoId && push(ProjectPath.messages(projectNanoId)),
    [push]
  );

  const currentUser = useCurrentUser();

  const { data: myProjectsData, isFetched } =
    useTotalsQuery<MyProjectsTotalCountQueryKey>({
      query: FETCH_PROJECTS_TOTAL_COUNT_QUERY,
      filters: {
        projectsFilters: {
          performerIds: [currentUser?.id]
        }
      },
      cacheKey: ProjectCache.totalsCacheKey(),
      options: {
        enabledPlaceholder: !!currentUser?.id,
        enabled: !!currentUser?.id
      }
    });

  if (currentUser?.client) {
    return isFetched && myProjectsData?.projects?.paginationInfo?.totalCount ? (
      <SingleUserDashboardPage />
    ) : (
      <CreateProjectInTeamPage afterCreateProject={handleAfterCreateProject} />
    );
  }

  return !currentUser?.client && <TeamDashboardPage />;
}

export default DashboardPage;
